import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import newLogo from "../images/allegria-logo.jpg";
import bullet from "../images/bullet.svg";
import facebook from "../images/facebook.svg";
import instagram from "../images/INSTA-ICON.svg";
import linkedin from "../images/linkedin.svg";
import MainNavBar from "./MainNavBar";

export const query = graphql`
  query indexQueryAndStaticMapQuery {
    staticMap {
      mapUrl
    }
    ourServicesGatsby: file(relativePath: { eq: "updated-our-services.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    insuranceImg: file(relativePath: { eq: "newInsurance.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideOne: file(relativePath: { eq: "slide-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideTwo: file(relativePath: { eq: "slide-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    slideThree: file(relativePath: { eq: "slide-3.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    aboutUs: file(relativePath: { eq: "oldthree.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dataJson {
      amenities_title
      serv_list
      home_title_1st
      home_title_2nd
      home_paragraph
      btn_title
      amenities_paragraph
      amen_list
      about_title
      about_paragraph
      insurance_list
      insurance_paragraph
      insurance_title
      serv_paragraph
      serv_title
      phone_number
      address
      facebook_link
      linkedin_link
      instagram_link
      email
    }
  }
`;
export default function IndexPage({ data }) {
  function encode(info) {
    return Object.keys(info)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(info[key])
      )
      .join("&");
  }

  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => alert("Thank you for submitting the form."))
      .catch((error) => alert(error));
  };
  // markup

  const slideOne = getImage(data.slideOne);
  const slideTwo = getImage(data.slideTwo);
  const slideThree = getImage(data.slideThree);
  const ourServicesGatsby = getImage(data.ourServicesGatsby);
  const insurance = getImage(data.insuranceImg);
  const aboutUs = getImage(data.aboutUs);

  return (
    <main className="main">
      <MainNavBar />
      {/* <div className="nav-bar">
        <div className="helps-width-nav">
          <div className="logo-cnt">
            <img className="logo" src={newLogo}></img>
          </div>
          <div className="nav-list">
            <div className="desktopNavBar">
              <ul className="nav">
                <li>
                  <Link to="/AboutUs/">About Us</Link>
                </li>
                <li>
                  <Link to="#service">Services</Link>
                </li>
                <li>
                  <Link to="#amenities">Amenities</Link>
                </li>
                <li>
                  <Link to="#insurance">Insurance</Link>
                </li>
                <li>
                  <Link to="#contact">Contact Us</Link>
                </li>
                 <li>
                  <Link to="#contact">
                    <a href="tel:5555555555">555-555-5555</a>
                  </Link>
                </li> 
              </ul>
            </div>
            <div className="mobileNavBar">
              <NavBar />
            </div>
          </div>
        </div>
      </div> */}

      <section className="main-bg">
        <div className="helps-width">
          <div className="bg-content-cnt">
            <div className="title-txt-cnt">
              <h4 className="title-txt">
                {data.dataJson.home_title_1st}
                <br />
                <div className="title-txt-2nd">
                  {data.dataJson.home_title_2nd}
                </div>
              </h4>
            </div>
            <div className="bg-txt-cnt">
              <p className="bg-txt">{data.dataJson.home_paragraph}</p>
            </div>
            <Link to="/ContactUs/" className="mobile-btn-position">
              <div className="btn-ctn">
                <button className="bg-btn">
                  <p className="bg-btn-txt">{data.dataJson.btn_title}</p>
                </button>
              </div>
            </Link>
          </div>
          <div className="image-frame-cnt">
            <div className="cf">
              <GatsbyImage
                className="pos top first-img"
                placeholder="blurred"
                image={slideOne}
                alt="Slide 1"
                height="281"
                width="500"
              />
              <GatsbyImage
                className="pos top second-img"
                placeholder="blurred"
                image={slideTwo}
                alt="Slide 1"
                height="281"
                width="500"
              />
              <GatsbyImage
                className="pos top third-img"
                placeholder="blurred"
                image={slideThree}
                alt="Slide 1"
                height="281"
                width="500"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="about-us">
        <div className="helps-width2">
          <div className="au-bg-container">
            <div className="au-bg-middle-ctn">
              <div id="about" className="about-us-bg"></div>
            </div>
          </div>
          <div className="about-us-txt-cnt">
            <div className="space-container">
              <div className="au-title-circle">
                <div className="au-txt">
                  <div className="left-circle-aboutUs"></div>

                  <h2>{data.dataJson.about_title}</h2>
                </div>
              </div>
              <div className="about-us-txt">
                <p>{data.dataJson.about_paragraph}</p>
              </div>
              <Link to="/AboutUs/" className="mobile-position">
                <button className="bg-btn-aboutUs">
                  <p className="bg-btn-txt-sections">
                    Learn More
                  </p>
                </button>
              </Link>
            </div>
          </div>
          <div className="left-image">
            {/* <img
              className="image-about-us"
              placeholder="blurred"
              // src="../images/about-us.jpg"
              src={data.dataJson.about_img}
              alt="BG"
            /> */}
            <GatsbyImage
              image={aboutUs}
              // image={data.dataJson.about_img}
              className="image-about-us"
              placeholder="blurred"
            />
          </div>
        </div>
      </section>
      <section id="service" className="our-services">
        <div className="helps-width-our">
          <div className="services-bg-container">
            <div className="services-bg-middle-ctn">
              <div id="service" className="services-bg"></div>
            </div>
          </div>
          <div className="services-txt-cnt">
            <div className="space-container">
              <div className="services-title-circle">
                <div className="left-circle"></div>
                <div className="services-txt">
                  <p>{data.dataJson.serv_title}</p>
                </div>
              </div>
              <div className="our-services-txt">
                <p>{data.dataJson.serv_paragraph}</p>
              </div>
              <div className="list-service ">
                <ul className="service-li">
                  {data.dataJson.serv_list && data.dataJson.serv_list.map((item) => {
                    return (
                      <li className="li-styles">
                        <span className="li-txt">{item}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="btn-mobile-ctn">
                <Link to="/Services/">
                  <button className="bg-btn-aboutUs">
                    <p className="bg-btn-txt-sections">
                      Learn More
                    </p>
                  </button>
                </Link>
              </div>
              {/* <div className="ourServices-button">
               <button className="bg-btn">
                 <p className="bg-btn-txt-sections">
                   {data.dataJson.btn_title}
                 </p>
               </button>
             </div> */}
            </div>
          </div>
          <div className="left-image service-mobile-image">
            {/* <StaticImage
             className="image-about-us"
             placeholder="blurred"
             src="../images/our-services.png"
             alt="BG"
           /> */}
            <GatsbyImage
              className="image-about-us"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <section id="insurance" className="about-us">
        <div className="helps-width2">
          <div className="au-bg-container">
            <div className="au-bg-middle-ctn">
              <div id="about" className="about-us-bg"></div>
            </div>
          </div>
          <div className="about-us-txt-cnt">
            <div className="space-container">
              <div className="au-title-circle">
                <div className="au-txt">
                  <div className="left-circle-aboutUs"></div>

                  <h2> {data.dataJson.insurance_title}</h2>
                </div>
              </div>
              <div className="about-us-txt">
                <p>{data.dataJson.insurance_paragraph}</p>
              </div>
              <div className="list-insurance ">
                <ul className="insurance-li">
                  {data.dataJson.insurance_list && data.dataJson.insurance_list.map((item) => {
                    return (
                      <li className="li-styles">
                        <span className="li-txt">{item}</span>{" "}
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* <Link to="/Insurance/">
                <button className="bg-btn-aboutUs">
                  <p className="bg-btn-txt">{data.dataJson.btn_title}</p>
                </button>
              </Link> */}
            </div>
          </div>
          <div className="left-image">
            {/* <StaticImage
             className="image-about-us"
             placeholder="blurred"
             src="../images/insurance.jpg"
             alt="BG"
           /> */}
            <GatsbyImage
              className="image-about-us"
              placeholder="blurred"
              image={insurance}
            />
          </div>
        </div>
      </section>

      <section id="amenities" className="amenities">
        <div className="helps-mobile-width">
          <div className="amenities-mobile-bg-container">
            <div className="amenities-bg-middle-ctn">
              <div id="amenities" className="amenities-bg"></div>
            </div>
          </div>
          <div className="amenities-mobile-image-ctn">
            <StaticImage className="amenities-mobile-image" src="../images/bg.jpg" alt="" />
          </div>
          <div className="amen-title-cnt">
            <h2 className="amen-title-txt">{data.dataJson.amenities_title}</h2>
            <p className="amen-subtitle-txt">
              {data.dataJson.amenities_paragraph}
            </p>
          </div>
        </div>
        <div className="amen-title-cnt-desktop">
          <h2 className="amen-title-txt">{data.dataJson.amenities_title}</h2>
          <div className="amen-cnt">
            <p className="amen-subtitle-txt">
              {data.dataJson.amenities_paragraph}
            </p>
            {/* <Link to="/Amenities/">
              <button className="bg-btn-amen">
                <p className="bg-btn-txt">{data.dataJson.btn_title}</p>
              </button>
            </Link> */}
          </div>
        </div>
        <div className="list-outer-cnt">
          <div className="list-cnt">
            {data.dataJson.amen_list && data.dataJson.amen_list.map((item) => {
              return (
                <li className="amen-li">
                  <img alt="bullet" className="bullet" src={bullet}></img>
                  {item}
                </li>
              );
            })}
          </div>
        </div>
      </section>

      {/* Contact Us */}
      <section id="contact" className="contact-us">
        <div className="helps-mobile-width">
          <div className="contact-mobile-bg-container">
            <div className="contact-bg-middle-ctn">
              <div id="amenities" className="contact-bg"></div>
            </div>
          </div>
          <div className="contact-us-cnt-mobile">
            <h2 className="contact">Contact Us</h2>
          </div>
        </div>
        <div className="contact-us-cnt">
          <h2 className="contact">Contact Us</h2>
        </div>
        <div className="contact-para-cnt">
          <p className="contact-para-txt">At Allegria we want you to feel comfortable whether you or a loved one is staying with us. If you have any questions or concerns we encourage you to reach out so we can answer all of your questions.</p>
        </div>
        <div className="contact-form">
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="input-area">
              <input
                className="reg-input"
                placeholder="Full Name"
                type="text"
                name="first-name"
                onChange={handleChange}
              />
              <input
                className="reg-input"
                placeholder="Email"
                type="email"
                name="email"
                onChange={handleChange}
              />
              <input
                className="reg-input"
                placeholder="Phone"
                type="phone"
                name="phone"
                onChange={handleChange}
              />
              <textarea
                name="message"
                placeholder="Notes"
                className="textarea"
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="submit-btn-container">
              <button className="submit-btn">
                <p className="submit-txt">Submit</p>
              </button>
            </div>
          </form>
          <div className="map-container">
            <div className="map">
              {/* {data.staticMap.mapUrl} */}
              {/* <a href="https://www.google.com/maps/place/124+Cedarhurst+Ave,+Cedarhurst,+NY+11516/@40.6226303,-73.7242443,21z/data=!4m5!3m4!1s0x89c265bf61062b31:0x66e21b40a9d814de!8m2!3d40.6226114!4d-73.7241107"> */}
              <a href={data.staticMap.mapUrl} className="googleMap">
                <img
                  src="https://maps.googleapis.com/maps/api/staticmap?center=40.9149169,-73.035196&zoom=19&size=500x500&maptype=roadmap&key=AIzaSyA5mbyOAEau5yB_XzX6jusgU9Z5VKaR9BY"
                  alt="map"
                  className="imageHover"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-container">
        <div className="footer-bg"></div>
        <div className="icon-logo-box">
          <div className="logo-footer-cnt">
            <img className="logo-footer" src={newLogo} alt=""></img>
            <div>
              <a href="tel:570-288-9315">
                <p>{data.dataJson.phone_number}</p>
              </a>
              <p>{data.dataJson.address}</p>
            </div>
          </div>
          <div className="social-icon-cnt">
            <a
              href={data.dataJson.facebook_link}
              className="social-media-icon"
            >
              <img
                className="social-media-icon"
                alt="facebook"
                src={facebook}
              />
            </a>
            <a
              href={data.dataJson.instagram_link}
              className="social-media-icon"
            >
              <img
                className="social-media-icon"
                alt="instagram"
                src={instagram}
              />
            </a>
            <a
              href={data.dataJson.linkedin_link}
              className="social-media-icon"
            >
              <img
                className="social-media-icon"
                alt="linkedin"
                src={linkedin}
              />
            </a>
          </div>
        </div>
        <div className="copy-cnt">
          <div className="accretive-cpyright">
            <a href="https://accretivesoftware.com/" >
              <p>Website powered by Accretive Software LLC</p>
            </a>
          </div>
          <div className="accretive-cpyright privacy-policy">
            <Link to="/PrivacyPolicy/" >
              <p>Privacy Policy</p>
            </Link>
          </div>
          <div className="website-copyright">
            <p className="copy">© {new Date().getFullYear()} VJJ Holdings LLC</p>
          </div>
        </div>
      </footer>
    </main>
  );
}
